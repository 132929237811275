import React from "react"
import Layout from "../layout"

import "../css/header.css"
import "../css/intro.css"
import "../css/floater.css"
import "../css/document.css"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

export default function HealthSafety() {
  // TODO: does this load the right sizes; e.g. 100% of container or 100vw of container?
  const images = useStaticQuery(graphql`
    {
      distanced: file(
        relativePath: { eq: "health-safety/2ndFloorEvents Print-35.jpg" }
      ) {
        ...jpgFragment
      }
    }
  `)

  return (
    <Layout>
      <header className="Header Header--image Header--safety">
        <h1>Health + Safety</h1>
        <figure>
          <Img fluid={images.distanced.childImageSharp.fluid} />
        </figure>
      </header>
      <section className="Floater Floater--weddings Floater--weddings--hs">
        <p className="Section__intro">
          As a turnkey space, Second&nbsp;Floor&nbsp;Events is proud to
          reimagine milestone occasions in the face of today's
          current&nbsp;events.
        </p>
        <div className="Floater__card">
          <p>
            For clients seeking <Link to="/food-drink">private events</Link>{" "}
            before 2021, <Link to="/contact">contact us</Link> to discuss how we
            can customize an event for you.
          </p>

          <p>
            For couples hoping to get married before 2021, customizable{" "}
            <a href="/micro-wedding.pdf" target="_blank">
              Micro-Wedding packages
            </a>{" "}
            are now available.
          </p>

          <hr className="vr" />

          <div className="Buttons">
            <Link
              className="Button Button--white Button--white-salmon"
              to="/contact"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </section>
      <section class="Document">
        <p className="Section__intro">
          To ensure the health and safety of our guests and staff, we have
          implemented the following policies:
        </p>

        <h3>Staff Masks & Uniforms</h3>
        <p>
          All staff are required to wear face masks upon entering the venue.
          Uniforms and masks are laundered daily.
        </p>

        <h3>Staff Health & Safety</h3>
        <p>
          All staff complete COVID-19 pre-shift screening checklists before
          entering the venue. Any employee deemed unfit to work in relation to
          COVID-19 is required to stay home from work for a minimum of 14 days.
        </p>

        <h3>Physical Distancing</h3>
        <p>
          We have placed signage throughout the venue to ensure proper physical
          distancing between guests from different households. At sit down
          events, all tables will be spaced a minimum of two metres apart.
        </p>

        <h3>Group Sizes</h3>
        <p>
          We will continue to limit our venue capacities in accordance with
          Toronto Public Health’s most recent protocols.
        </p>

        <h3>Enhanced Sanitation Measures</h3>
        <p>
          We’ve placed hand sanitizer stations throughout the venue and frequent
          touch points will be sanitized at regular intervals. Every employee is
          required to wash their hands for 20 seconds upon entering the venue,
          as well as throughout their shift. Following each event, the venue
          will be thoroughly cleaned and sanitized, including wiping down all
          touch points, tables and chairs.
        </p>

        <h3>Washroom Access</h3>
        <p>
          We will continue to limit the number of guests in the washroom at a
          time. An assigned attendant will also sanitize all high touchpoint
          surfaces throughout each event.
        </p>

        <h3>Vendors & Delivery Drivers</h3>
        <p>
          Signage is posted around the venue to remind vendors and delivery
          drivers of our health and safety policies, including wearing a mask
          indoors, using hand sanitizer and physical distancing from staff and
          guests. We also work closely with vendors to contact trace.
        </p>

        <h3>Staying Connected</h3>
        <p>
          We contact trace at every event and continue to follow Toronto Public
          Heath’s protocols for gatherings and safety, found{" "}
          <a
            href="https://www.toronto.ca/wp-content/uploads/2020/07/9636-COVID-19-Indoor-Outdoor-Events-Gatherings.pdf"
            target="_blank"
          >
            here
          </a>
          .
        </p>
      </section>
      <section class="Document Document--CTA">
        <div style={{ textAlign: "center", position: "relative", zIndex: 4 }}>
          <p>
            Whether you're simply looking for a beautiful intimate space to
            gather with your closest family and friends, or are looking for a
            full-service event to be planned for you, a dedicated Event Manager
            will assist you from booking date through event&nbsp;day.
          </p>
          <p>
            <Link to="/contact" className="Button">
              Get in Touch
            </Link>
          </p>
        </div>
      </section>
    </Layout>
  )
}
